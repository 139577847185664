body {
  margin: 0;
  padding: 0 10px;
  background-color: black;
  font-family: mono;
}

#title {
  text-align: center;
  font-weight: lighter;
}

#content {
  min-width: 300px;
  max-width: 550px;
  margin: 0 auto
}

#satellite-image {
  margin: 5px 0;
}
